var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading && !_vm.error ? _c('div', [_vm.title || _vm.$slots['title'] ? _c('div', {
    staticClass: "row align-items-start mb-2"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._t("title", function () {
    return [_vm._v(_vm._s(_vm.title))];
  })], 2)]), _c('div', {
    staticClass: "col text-right"
  }, [_vm._t("actions")], 2)]) : _vm._e(), _vm.description || _vm.$slots['description'] ? _c('p', {
    staticClass: "mb-5"
  }, [_vm._t("description", function () {
    return [_vm._v(_vm._s(_vm.description))];
  })], 2) : _vm._e(), _vm._t("default")], 2) : _vm.loading && !_vm.error ? _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_vm._m(0)]) : _c('div', [_c('div', {
    staticClass: "alert alert-danger"
  }, [_vm._v("There was an error loading this chart.")])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "spinner-border spinner-lg text-secondary mb-4",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading data...")])]), _c('div', {
    staticClass: "font-w600"
  }, [_vm._v("Loading data...")])]);

}]

export { render, staticRenderFns }
var render = function render(){
  var _vm$emissions, _vm$prevEmissions, _vm$emissions2, _vm$prevEmissions2, _vm$emissions3, _vm$prevEmissions3, _vm$emissions4, _vm$prevEmissions4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.analyticsOptions.selectedId ? _c('div', [_c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": ((_vm$emissions = _vm.emissions) === null || _vm$emissions === void 0 ? void 0 : _vm$emissions.scope1) / 1000,
      "prev-value": ((_vm$prevEmissions = _vm.prevEmissions) === null || _vm$prevEmissions === void 0 ? void 0 : _vm$prevEmissions.scope1) / 1000,
      "precision": "2",
      "icon-class": "fa-flame",
      "description": "Scope 1 Emissions",
      "units": "t CO2e",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['emissions-summary'] || _vm.analyticsLoading,
      "prev-value-loading": _vm.loadingAction.getAnalytics['emissions-summary'] || _vm.analyticsLoading,
      "error": _vm.errorAction.getAnalytics['emissions-summary']
    }
  })], 1), _c('div', {
    staticClass: "col"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": ((_vm$emissions2 = _vm.emissions) === null || _vm$emissions2 === void 0 ? void 0 : _vm$emissions2.scope2) / 1000,
      "prev-value": ((_vm$prevEmissions2 = _vm.prevEmissions) === null || _vm$prevEmissions2 === void 0 ? void 0 : _vm$prevEmissions2.scope2) / 1000,
      "precision": "2",
      "icon-class": "fa-plug",
      "description": "Scope 2 Emissions",
      "units": "t CO2e",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['emissions-summary'] || _vm.analyticsLoading,
      "prev-value-loading": _vm.loadingAction.getAnalytics['emissions-summary'] || _vm.analyticsLoading,
      "error": _vm.errorAction.getAnalytics['emissions-summary']
    }
  })], 1), _c('div', {
    staticClass: "col"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": ((_vm$emissions3 = _vm.emissions) === null || _vm$emissions3 === void 0 ? void 0 : _vm$emissions3.scope3) / 1000,
      "prev-value": ((_vm$prevEmissions3 = _vm.prevEmissions) === null || _vm$prevEmissions3 === void 0 ? void 0 : _vm$prevEmissions3.scope3) / 1000,
      "precision": "2",
      "icon-class": "fa-conveyor-belt-boxes",
      "description": "Scope 3 Emissions",
      "units": "t CO2e",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['emissions-summary'] || _vm.analyticsLoading,
      "prev-value-loading": _vm.loadingAction.getAnalytics['emissions-summary'] || _vm.analyticsLoading,
      "error": _vm.errorAction.getAnalytics['emissions-summary']
    }
  })], 1), _c('div', {
    staticClass: "col"
  }, [_c('ConsumptionCard', {
    staticClass: "flex-grow-1",
    attrs: {
      "value": ((_vm$emissions4 = _vm.emissions) === null || _vm$emissions4 === void 0 ? void 0 : _vm$emissions4.total) / 1000,
      "prev-value": ((_vm$prevEmissions4 = _vm.prevEmissions) === null || _vm$prevEmissions4 === void 0 ? void 0 : _vm$prevEmissions4.total) / 1000,
      "icon-class": "fa-leaf text-success",
      "description": "Total Emissions",
      "units": "t CO2e",
      "comparison-period": _vm.analyticsOptions.comparePeriod,
      "value-loading": _vm.loadingAction.getAnalytics['emissions-summary'] || _vm.analyticsLoading,
      "prev-value-loading": _vm.loadingAction.getAnalytics['emissions-summary'] || _vm.analyticsLoading,
      "error": _vm.errorAction.getAnalytics['emissions-summary']
    }
  })], 1)]), _c('div', {
    staticClass: "col bg-lighter rounded-md px-4 py-3 mb-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Granularity")]), _c('FormItem', {
    staticClass: "mb-0",
    attrs: {
      "id": "granularity",
      "type": "select",
      "options": [{
        label: 'Half-hourly',
        value: 'halfhourly'
      }, {
        label: 'Hourly',
        value: 'hourly'
      }, {
        label: 'Daily',
        value: 'daily'
      }, {
        label: 'Weekly',
        value: 'weekly'
      }, {
        label: 'Monthly',
        value: 'monthly'
      }, {
        label: 'Quarterly',
        value: 'quarterly'
      }, {
        label: 'Annually',
        value: 'yearly'
      }],
      "disabled": _vm.loadingAction.getConsumptions
    },
    on: {
      "input": _vm.onGranularityUpdate
    },
    model: {
      value: _vm.analyticsOptions.granularity,
      callback: function ($$v) {
        _vm.$set(_vm.analyticsOptions, "granularity", $$v);
      },
      expression: "analyticsOptions.granularity"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Highlight")]), _c('FormItem', {
    staticClass: "mb-0",
    attrs: {
      "id": "highlight",
      "type": "select",
      "options": [{
        label: 'None',
        value: ''
      }, {
        label: 'Source',
        value: 'source'
      }, {
        label: 'Compare To',
        value: 'compare'
      }]
    },
    on: {
      "input": _vm.refreshChart
    },
    model: {
      value: _vm.options.highlight,
      callback: function ($$v) {
        _vm.$set(_vm.options, "highlight", $$v);
      },
      expression: "options.highlight"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Graph Type")]), _c('a', {
    staticClass: "font-w600 px-3 py-1",
    class: {
      'bg-primary text-white border rounded-md': _vm.options.chartType === 'bar'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.options.chartType = 'bar';
      }
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-chart-column mr-1"
  }), _vm._v(" Bar")]), _c('a', {
    staticClass: "font-w600 px-3 py-1",
    class: {
      'bg-primary text-white border rounded-md': _vm.options.chartType === 'line'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.options.chartType = 'line';
      }
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-chart-line mr-1"
  }), _vm._v(" Line")])])])]), !_vm.loading && !_vm.loadingAction.getAnalytics['emissions-summary'] && !_vm.analyticsLoading ? _c('Chart', {
    staticClass: "mb-4",
    staticStyle: {
      "height": "500px"
    },
    attrs: {
      "option": _vm.emissionsChart
    }
  }) : _c('Spinner', {
    staticClass: "mb-10"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    class: _vm.showAssetContractRenewable ? 'col-xl-4' : 'col-xl-6'
  }, [_c('Block', [_c('ChartCard', {
    attrs: {
      "title": "Scope Breakdown",
      "loading": _vm.loadingAction.getAnalytics['emissions-summary'],
      "error": _vm.errorAction.getAnalytics['emissions-summary']
    }
  }, [[_vm.emissions.total ? _c('div', [_c('apexchart', {
    attrs: {
      "height": "350",
      "options": _vm.scopeBreakdownChart.options,
      "series": _vm.scopeBreakdownChart.series
    }
  }), _c('div', {
    staticClass: "mb-0 mt-2 text-muted"
  }, [_c('strong', [_vm._v("Source:")]), _vm._v(" BEIS")])], 1) : _vm._e()]], 2)], 1)], 1), _vm.showAssetContractRenewable ? _c('div', {
    staticClass: "col-xl-4"
  }, [_c('Block', [_c('ChartCard', {
    attrs: {
      "title": "Renewable Percentages",
      "description": "Based on average contract renewable percentages for all accounts on this asset.",
      "loading": _vm.loadingAction.getAnalytics['asset-contract-renewable'],
      "error": _vm.errorAction.getAnalytics['asset-contract-renewable']
    }
  }, [[_vm.analytics['asset-contract-renewable'] && !_vm.loadingAction.getAnalytics['asset-contract-renewable'] ? _c('div', [_c('div', [_c('div', {
    staticClass: "row align-items-center mb-4"
  }, [_vm.analytics['asset-contract-renewable'].results.gas && _vm.analytics['asset-contract-renewable'].results.gas !== 'N/A' ? _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_c('UtilityTypeIcon', {
    attrs: {
      "type": "gas",
      "text": false,
      "icon-class": "fa-2x"
    }
  })], 1), _c('div', {
    staticClass: "flex-grow-1"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Gas")]), _c('h5', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.analytics['asset-contract-renewable'].results.gas, 2)) + "% "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Renewable")])])])])]) : _vm._e(), _vm.analytics['asset-contract-renewable'].results.electricity && _vm.analytics['asset-contract-renewable'].results.electricity !== 'N/A' ? _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_c('UtilityTypeIcon', {
    attrs: {
      "type": "electricity",
      "text": false,
      "icon-class": "fa-2x"
    }
  })], 1), _c('div', {
    staticClass: "flex-grow-1"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Electricity")]), _c('h5', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.analytics['asset-contract-renewable'].results.electricity, 2)) + "% "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Renewable")])])])])]) : _vm._e(), _vm.analytics['asset-contract-renewable'].results.water && _vm.analytics['asset-contract-renewable'].results.water !== 'N/A' ? _c('div', {
    staticClass: "col-md-6 mt-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_c('UtilityTypeIcon', {
    attrs: {
      "type": "water",
      "text": false,
      "icon-class": "fa-2x"
    }
  })], 1), _c('div', {
    staticClass: "flex-grow-1"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Water")]), _c('h5', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.analytics['asset-contract-renewable'].results.water, 2)) + "% "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Renewable")])])])])]) : _vm._e(), _vm.analytics['asset-contract-renewable'].results.waste && _vm.analytics['asset-contract-renewable'].results.waste !== 'N/A' ? _c('div', {
    staticClass: "col-md-6 mt-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_c('UtilityTypeIcon', {
    attrs: {
      "type": "waste",
      "text": false,
      "icon-class": "fa-2x"
    }
  })], 1), _c('div', {
    staticClass: "flex-grow-1"
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Waste")]), _c('h5', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.analytics['asset-contract-renewable'].results.waste, 2)) + "% "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Renewable")])])])])]) : _vm._e()]), _vm.analytics['asset-contract-renewable'].results.total && _vm.analytics['asset-contract-renewable'].results.total !== 'N/A' ? _c('div', {
    staticClass: "h2 pt-4 border-top mb-2"
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.analytics['asset-contract-renewable'].results.total, 2)) + "% "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Renewable (Overall avg.)")])]) : _vm._e()])]) : _vm._e()]], 2)], 1)], 1) : _vm._e(), _c('div', {
    class: _vm.showAssetContractRenewable ? 'col-xl-4' : 'col-xl-6'
  }, [_c('Block', [_c('ChartCard', {
    attrs: {
      "title": "EPC Ratings",
      "loading": _vm.loadingAction.getAnalytics['epc-ratings'],
      "error": _vm.errorAction.getAnalytics['epc-ratings']
    },
    scopedSlots: _vm._u([{
      key: "description",
      fn: function () {
        return [_vm._v("Total number of approved EPC ratings for this " + _vm._s(_vm.analyticsOptions.type) + ", grouped by its performance category.")];
      },
      proxy: true
    }], null, false, 3651695963)
  }, [[_vm.analytics[`epc-ratings`] && _vm.analytics[`epc-ratings`].results ? _c('div', [_c('apexchart', {
    attrs: {
      "options": _vm.epcRatingsChart.options,
      "series": _vm.epcRatingsChart.series
    }
  })], 1) : _vm._e()]], 2)], 1)], 1)])], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }